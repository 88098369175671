<template>
  <vue-good-table
    :columns="columns"
    :rows="rows"
    :pagination-options="{
      enabled: true,
      perPage:pageLength
    }"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >

      <!-- Column: Estimated Cost -->
      <div
        v-if="props.column.field === 'Estimated_cost'"
        class="text-nowrap"
      >
        ${{ props.row.estimated_cost.toFixed(2) }}
      </div>

      <!-- Column: Tonu -->
      <div
        v-if="props.column.field === 'Tonu'"
        class="text-nowrap"
      >
        ${{ props.row.tonu.toFixed(2) }}
      </div>

      <!-- Column: Tolls -->
      <div
        v-if="props.column.field === 'Tolls'"
        class="text-nowrap"
      >
        ${{ props.row.tolls.toFixed(2) }}
      </div>

      <!-- Column: Fuel Surcharge -->
      <div
        v-if="props.column.field === 'Fuel_surcharge'"
        class="text-nowrap"
      >
        ${{ props.row.fuel_surcharge.toFixed(2) }}
      </div>

      <!-- Column: Gross Pay -->
      <div
        v-if="props.column.field === 'Gross_pay'"
        class="text-nowrap"
      >
        ${{ props.row.gross_pay.toFixed(2) }}
      </div>

      <!-- Column: Recent -->
      <div
        v-if="props.column.field === 'Recent'"
        class="text-nowrap"
      >
        <b-badge
          v-if="props.row.recent"
          variant="success"
        >
          Recent
        </b-badge>
        <b-badge
          v-if="!props.row.recent"
          variant="danger"
        >
          Old
        </b-badge>
      </div>

      <!-- Column: Action -->
      <span v-else-if="props.column.field === 'action'">
        <span>
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item>
              <feather-icon
                icon="Edit2Icon"
                class="mr-50"
              />
              <span>Edit</span>
            </b-dropdown-item>
            <b-dropdown-item>
              <feather-icon
                icon="TrashIcon"
                class="mr-50"
              />
              <span>Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </span>
      </span>

      <!-- Column: Common -->
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>

    <!-- pagination -->
    <template
      slot="pagination-bottom"
      slot-scope="props"
    >
      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-0 mt-1">
          <span class="text-nowrap">
            Showing 1 to
          </span>
          <b-form-select
            v-model="pageLength"
            :options="['5','10','15']"
            class="mx-1"
            @input="(value)=>props.perPageChanged({currentPerPage:value})"
          />
          <span class="text-nowrap "> of {{ props.total }} entries </span>
        </div>
        <div>
          <b-pagination
            :value="1"
            :total-rows="props.total"
            :per-page="pageLength"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @input="(value)=>props.pageChanged({currentPage:value})"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
    </template>
  </vue-good-table>
</template>

<script>
import {
  BPagination, BFormSelect, BDropdown, BDropdownItem, BBadge,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BBadge,
  },
  props: {
    rows: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      pageLength: 10,
      columns: [
        {
          label: 'Stop 1',
          field: 'stop_1',
          filterOptions: {
            enabled: true,
            placeholder: 'Stop 1',
          },
        },
        {
          label: 'Stop 2',
          field: 'stop_2',
          filterOptions: {
            enabled: true,
            placeholder: 'Stop 2',
          },
        },
        {
          label: 'Load ID',
          field: 'load_id',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Date',
          },
        },
        {
          label: 'Trip ID',
          field: 'trip_id',
          filterOptions: {
            enabled: true,
            placeholder: 'Trip ID',
          },
        },
        {
          label: 'Estimated Cost',
          field: 'Estimated_cost',
          filterOptions: {
            enabled: true,
            placeholder: 'Estimated Cost',
          },
        },
        {
          label: 'Tonu',
          field: 'Tonu',
          filterOptions: {
            enabled: true,
            placeholder: 'Tonu',
          },
        },
        {
          label: 'Tolls',
          field: 'Tolls',
          filterOptions: {
            enabled: true,
            placeholder: 'Tolls',
          },
        },
        {
          label: 'Fuel Surcharge',
          field: 'Fuel_surcharge',
          filterOptions: {
            enabled: true,
            placeholder: 'Fuel Surcharge',
          },
        },
        {
          label: 'Gross Pay',
          field: 'Gross_pay',
          filterOptions: {
            enabled: true,
            placeholder: 'Gross Pay',
          },
        },
        {
          label: 'Payment Date',
          field: 'payment_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Payment Date',
          },
        },
        {
          label: 'Driver Name',
          field: 'driver_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Driver Name',
          },
        },
        {
          label: 'Recent',
          field: 'Recent',
          filterOptions: {
            enabled: true,
            placeholder: 'Recent',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
    }
  },
}
</script>

<style>

</style>
